import React, { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Dropdown from "react-bootstrap/Dropdown";
import settingService from "./settingServices";
import http from "../../httpcommon";
import { CircularProgress } from "@mui/material";

const Template = () => {
  const [open, setOpen] = useState(false);
  const [tempId, setTempId] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState("Choose Template");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleCloseSnackbar = () => {
    setOpen(false);
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const getTemplateID = async (dropdownItem) => {
    try {
      const baseURL1 = http.defaults.baseURL;
      const response = await fetch(`${baseURL1}admin/downloadtemplates`);

      if (!response.ok) {
        throw new Error(
          `Failed to fetch template information: ${response.statusText}`
        );
      }

      const templateInfo = await response.json();
      const { templateType, id } = determineTemplateInfo(
        templateInfo,
        dropdownItem
      );

      if (id === null || templateType === null) {
        setSnackbarMessage(`Template for ${dropdownItem} not found.`);
        setSnackbarSeverity("error");
        setOpen(true);
        return;
      }

      setTempId(id);
      setSelectedTemplate(templateType);
      setSnackbarMessage(`Template type: ${templateType}`);
      setSnackbarSeverity("success");
      setOpen(true);
    } catch (error) {
      console.error("Error while fetching template information:", error);
      setSnackbarMessage("Error while fetching template information");
      setSnackbarSeverity("error");
      setOpen(true);
    }
  };

  const determineTemplateInfo = (templateInfo, dropdownItem) => {
    switch (dropdownItem) {
      case "SALESPIPELINE":
        return {
          templateType: "SALESPIPELINE_TEMPLATE",
          id: templateInfo.SALESPIPELINE_TEMPLATE?.id,
        };
      case "ORDER":
        return {
          templateType: "ORDER_TEMPLATE",
          id: templateInfo.ORDER_TEMPLATE?.id,
        };
      case "SAMPLE":
        return {
          templateType: "SAMPLE_TEMPLATE",
          id: templateInfo.SAMPLE_TEMPLATE?.id,
        };
      case "BUDGET":
        return {
          templateType: "BUDGET_TEMPLATE",
          id: templateInfo.BUDGET_TEMPLATE?.id,
        };
      case "FORECAST":
        return {
          templateType: "FORECAST_TEMPLATE",
          id: templateInfo.FORECAST_TEMPLATE?.id,
        };
      default:
        return {
          templateType: null,
          id: null,
        };
    }
  };
  //update Existing template
  const handleUpdate = () => {
    debugger;
    setLoading(true);
    const id = tempId;
    const file = selectedFile;

    if (!file) {
      setSnackbarMessage("No file selected");
      setSnackbarSeverity("error");
      setOpen(true);
      return;
    }

    if (!id) {
      setSnackbarMessage("New/Blank template can not be added");
      setSnackbarSeverity("error");
      setOpen(true);
      return;
    }

    settingService
      .putTemplateUpdate(id, file)
      .then((response) => {
        console.log("template_info", response.data);
        if (response.status >= 200 && response.status < 300) {
          setSnackbarMessage("Template Updated Successfully");
          setSnackbarSeverity("success");
          setOpen(true);
          setLoading(false);
        } else {
          // Handle other non-successful scenarios if needed
          setSnackbarMessage(
            `Error updating template - ${response.data.statusText}`
          );
          setSnackbarSeverity("error");
          setOpen(true);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error updating template:", error);
        setSnackbarMessage("Error updating template");
        setSnackbarSeverity("error");
        setOpen(true);
        setLoading(false);
      });
  };

  //save new template
  //   const handleSaveTemplate = () => {
  //     debugger;
  //     const testID = tempId;
  //     const file = selectedFile;
  //     const templateType = selectedTemplate;
  //     console.log("templateType", templateType);
  //     if (testID) {
  //       setSnackbarMessage("template already saved, kindly update if require");
  //       setSnackbarSeverity("error");
  //       setOpen(true);
  //       return;
  //     }
  //     if (!file) {
  //       setSnackbarMessage("No file selected");
  //       setSnackbarSeverity("error");
  //       setOpen(true);
  //       return;
  //     }
  //     settingService
  //       .postTemplateSave(templateType, file)
  //       .then((response) => {
  //         console.log("template_info", response);
  //       })
  //       .catch((error) => {
  //         console.error("Error updating template:", error);
  //       });
  //   };

  return (
    <div className="col-12 col-lg-12 col-xl-12 d-flex">
      <div className="card w-100 rounded-4">
        <div className="card-body">
          <Stack spacing={2} sx={{ width: "100%" }}>
            <Snackbar
              open={open}
              autoHideDuration={2000}
              onClose={handleCloseSnackbar}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Alert
                onClose={handleCloseSnackbar}
                severity={snackbarSeverity}
                sx={{ width: "100%", marginTop: "2rem" }}
              >
                {snackbarMessage}
              </Alert>
            </Snackbar>
          </Stack>

          <div className="d-flex justify-content-between align-items-center mb-3">
            <h2 className="mb-0 dash-heading">Template Settings</h2>
          </div>

          {/* <form> */}
          <Dropdown className="me-3" style={{ marginTop: "2rem" }}>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              {selectedTemplate}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => getTemplateID("ORDER")}>
                ORDER
              </Dropdown.Item>
              <Dropdown.Item onClick={() => getTemplateID("SALESPIPELINE")}>
                SALESPIPELINE
              </Dropdown.Item>
              <Dropdown.Item onClick={() => getTemplateID("SAMPLE")}>
                SAMPLE
              </Dropdown.Item>
              <Dropdown.Item onClick={() => getTemplateID("BUDGET")}>
                BUDGET
              </Dropdown.Item>
              <Dropdown.Item onClick={() => getTemplateID("FORECAST")}>
                FORECAST
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <div className="d-flex flex-column mb-2" style={{ width: "50%" }}>
            <input
              type="file"
              className="form-control"
              style={{
                marginTop: "2rem",
                marginBottom: "1rem",
              }}
              onChange={handleFileChange}
            />
          </div>

          <button
            type="button"
            className="btn btn-primary mt-3"
            onClick={handleUpdate}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Update"
            )}
          </button>
          {/* <button
              type="button"
              className="btn btn-primary mt-3"
              onClick={handleSaveTemplate}
              style={{ marginLeft: "2rem" }}
            >
              Save
            </button> */}
          {/* </form> */}
        </div>
      </div>
    </div>
  );
};

export default Template;
