import { NavLink, NavNavLink } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";
import AuthService from "../../services/AuthServices";
function SideNav() {
  var jwtToken = localStorage.getItem("token");
  const jwtDecode = jwtToken ? jwt_decode(jwtToken) : null;
  const role = jwtDecode ? jwtDecode.role[0].name : null;
  const userId = jwtDecode ? jwtDecode.Id : null;

  const [userData, setUserData] = useState({});
  const getUser = () => {
    AuthService.getUser(userId)
      .then((response) => {
        setUserData(response.data.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    getUser();
  }, []);

  const navLinks = [
    {
      to: "/dashboard/gallery",
      class: "has-arrow",
      icon: "fa fa-image",
      title: "Gallery",
      moduleId: 1,
    },
    {
      to: "/dashboard/order",
      class: "has-arrow",
      icon: "fa fa-cart-arrow-down",
      title: "Orders",
      moduleId: 2,
    },
    {
      to: "/dashboard/sample",
      class: "has-arrow",
      icon: "bi bi-basket2-fill",
      title: "Sample",
      moduleId: 3,
    },
    {
      to: "/dashboard/payment",
      class: "has-arrow",
      icon: "bi bi-cash",
      title: "Payments",
      moduleId: 4,
    },
    {
      to: "/dashboard/complaint",
      class: "has-arrow",
      icon: "bi bi-cloud-arrow-down-fill",
      title: "Complaints",
      moduleId: 5,
    },
    {
      to: "/dashboard/dcnote",
      class: "has-arrow",
      icon: "bi bi-credit-card-2-back",
      title: "Debit/Credit Notes",
      moduleId: 6,
    },
    {
      to: "/dashboard/technicalquery",
      class: "has-arrow",
      icon: "fa fa-cart-arrow-down",
      title: "Technical Enquiry",
      moduleId: 7,
    },
    {
      to: "/dashboard/salesPipeline",
      class: "has-arrow",
      icon: "fa fa-files-o",
      title: "Sales Pipeline",
      moduleId: 8,
    },
    {
      to: "/dashboard/budget",
      class: "",
      icon: "bi bi-currency-dollar",
      title: "Budget",
      moduleId: 9,
    },
    {
      to: "/dashboard/forecast",
      class: "",
      icon: "bi bi-arrow-clockwise",
      title: "Forecast",
      moduleId: 10,
    },
  ];

  return (
    <>
      <aside class="sidebar-wrapper" data-simplebar="true">
        <div class="sidebar-header">
          <div class="logo-background">
            <img
              src="/assets/images/logo.png"
              class="logo-icon"
              alt="logo icon"
            />
          </div>

          <div class="toggle-icon ms-auto show-mobile">
            <i class="bi bi-list"></i>
          </div>
        </div>

        <ul class="metismenu" id="menu">
          <li></li>
          {role === "ROLE_SUB_ADMIN" ? (
            userData?.modules?.map((item) => {
              const filteredLink = navLinks.find(
                (link) => link.moduleId === item.moduleId
              );
              //console.log("ROLE_SUB_ADMIN", filteredLink)
              return filteredLink ? (
                <li key={filteredLink.moduleId}>
                  <NavLink to={filteredLink.to} className={filteredLink.class}>
                    <div className="parent-icon">
                      <i className={filteredLink.icon}></i>
                    </div>
                    <div className="menu-title">{filteredLink.title}</div>
                  </NavLink>
                </li>
              ) : null;
            })
          ) : (
            <>
              <li>
                <NavLink to="/dashboard/gallery" class="has-arrow">
                  <div class="parent-icon">
                    <i class="fa fa-image"></i>
                  </div>
                  <div class="menu-title">Gallery</div>
                </NavLink>
              </li>
              <li>
                <NavLink to="/dashboard/order" class="has-arrow">
                  <div class="parent-icon">
                    <i class="fa fa-cart-arrow-down" aria-hidden="true"></i>
                  </div>
                  <div class="menu-title">Orders</div>
                </NavLink>
              </li>
              <li>
                <NavLink to="/dashboard/sample" class="has-arrow">
                  <div class="parent-icon">
                    <i class="bi bi-basket2-fill"></i>
                  </div>
                  <div class="menu-title">Sample</div>
                </NavLink>
              </li>

              <li>
                <NavLink class="has-arrow" to="/dashboard/payment">
                  <div class="parent-icon">
                    <i class="bi bi-cash"></i>
                  </div>
                  <div class="menu-title">Payments</div>
                </NavLink>
              </li>
              <li>
                <NavLink class="has-arrow" to="/dashboard/complaint">
                  <div class="parent-icon">
                    <i class="bi bi-cloud-arrow-down-fill"></i>
                  </div>
                  <div class="menu-title">Complaints</div>
                </NavLink>
              </li>

              <li>
                <NavLink class="has-arrow" to="/dashboard/dcnote">
                  <div class="parent-icon">
                    <i class="bi bi-credit-card-2-back"></i>
                  </div>
                  <div class="menu-title">Debit/Credit Notes</div>
                </NavLink>
              </li>
              {/* <li>
                    <a class="has-arrow" href="enquiry.html">
                        <div class="parent-icon"><i class="fa fa-wrench"></i></div>
                        <div class="menu-title">Techinical Enquiry</div>
                    </a>
                </li> */}
              <li>
                <NavLink to="/dashboard/technicalquery" class="has-arrow">
                  <div class="parent-icon">
                    <i class="fa fa-cart-arrow-down" aria-hidden="true"></i>
                  </div>
                  <div class="menu-title">Technical Enquiry</div>
                </NavLink>
              </li>
              <li>
                <NavLink class="has-arrow" to="/dashboard/salesPipeline">
                  <div class="parent-icon">
                    <i class="fa fa-files-o"></i>
                  </div>
                  <div class="menu-title">Sales Pipeline</div>
                </NavLink>
              </li>
              {/* <li>
            <a href="forecast.html">
              <div class="parent-icon">
                <i class="bi bi-arrow-clockwise"></i>
              </div>
              <div class="menu-title">Forecast</div>
            </a>
          </li> */}
              <li>
                <NavLink to="/dashboard/budget">
                  <div class="parent-icon">
                    <i class="bi bi-currency-dollar"></i>
                  </div>
                  <div class="menu-title">Budget</div>
                </NavLink>
              </li>
              <li>
                <NavLink to="/dashboard/forecast">
                  <div class="parent-icon">
                    <i class="bi bi-arrow-clockwise"></i>
                  </div>
                  <div class="menu-title">Forecast</div>
                </NavLink>
              </li>

              {role == "ROLE_ADMIN" ? (
                <>
                  <li>
                    <NavLink class="has-arrow" to="/dashboard/user">
                      <div class="parent-icon">
                        <i class="fa fa-user" aria-hidden="true"></i>
                      </div>
                      <div class="menu-title">Users</div>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink class="has-arrow" to="/dashboard/manager">
                      <div class="parent-icon">
                        <i class="fa fa-users" aria-hidden="true"></i>
                        {/* <i class="fa fa-user" ></i> */}
                      </div>
                      <div class="menu-title">Managers</div>
                    </NavLink>
                  </li>
                </>
              ) : null}
              {role == "ROLE_ADMIN" ? (
                <li class="side-nav-item">
                  <a
                    data-bs-toggle="collapse"
                    href="#sidebarSetting"
                    aria-expanded="false"
                    aria-controls="sidebarSetting"
                    class="side-nav-link collapsed"
                  >
                    <div class="parent-icon">
                      <i class="bi bi-gear-fill"></i>
                    </div>
                    <div class="menu-title">Setting</div>

                    <span class="nct-dropdown">
                      {" "}
                      <i class="fa fa-angle-down" aria-hidden="true"></i>{" "}
                    </span>
                  </a>
                  <div class="collapse" id="sidebarSetting">
                    <ul class="side-nav-second-level">
                      <li>
                        <NavLink to="/dashboard/smtp">SMTP</NavLink>
                      </li>
                      <li>
                        <NavLink to="/dashboard/currency">Currency</NavLink>
                      </li>
                      <li>
                        <NavLink to="/dashboard/country">Country</NavLink>
                      </li>
                      <li>
                        <NavLink to="/dashboard/company">Company</NavLink>
                      </li>
                      <li>
                        <NavLink to="/dashboard/application">
                          Application
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/dashboard/template">Template</NavLink>
                      </li>
                    </ul>
                  </div>
                </li>
              ) : null}
            </>
          )}
        </ul>
      </aside>
    </>
  );
}

export default SideNav;
