import { useEffect, useState, useRef } from "react";
import UserServices from "../../services/UserServices";
import ServerSideTable from "../serverSideTable";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  LinearProgress,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "../style.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import * as React from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import jwt_decode from "jwt-decode";
import Docread from "./docread";
import OrderStatus from "./orderStatus";
import DistributorServices from "../../services/DistributorServices";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import swal from "sweetalert";
import ForecastService from "./ForecastService";
import dayjs from "dayjs";
import { useMemo } from "react";
import AdminServices from "../../services/AdminServices";
import UploadExcel from "../uploadExcel";
import { DataGrid } from "@mui/x-data-grid";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Forecast() {
  const [gellaryData, setGellaryData] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState("");
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [fileCount, setFileCount] = useState(0);
  const [load, setLoad] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [principalList, setPrincipalList] = useState([]);
  const [principalselect, setPrincipalselect] = useState("");
  const [status, setStatus] = useState("");
  const [orderStatus, setOrderStatus] = useState("");
  const [annualBudgetTotal, setAnnualBudgetTotal] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [principalFilter, setPrincipalFilter] = useState("");
  const [distributerFilter, setDistributorFilter] = useState("");
  const [forecastId, setForecastIdFilter] = useState("");

  //--------------------------------------------
  const [incoTerms, setIncoTerms] = useState("EXW");
  const [freight, setFreight] = useState("Yes");
  const [insurance, setInsurance] = useState("");
  const [paymentTerms, setPaymentTerms] = useState("");
  const [invoice, setInvoice] = useState("Yes");
  //----------------------------------------------
  const [recentModified, setRecentModified] = useState(false);
  const statusList = ["PENDING", "REJECTED", "APPROVED"];
  const tableRef = useRef(null);

  ///------------
  useEffect(() => {
    console.log(
      "test order",
      orderStatus,
      principalFilter,
      distributerFilter,
      forecastId,
      status,
      startDate,
      endDate
    );
  }, [
    orderStatus,
    status,
    principalFilter,
    distributerFilter,
    forecastId,
    startDate,
    endDate,
  ]);

  //Add product Form Data
  const [updateId, setUpdateId] = useState();
  const [principal, setPrincipal] = useState("");
  const [orderId, setOrderId] = useState("");
  const [productCode, setProductCode] = useState("");
  const [paymentDate, setPaymentDate] = useState();

  const [productName, setProductName] = useState("");
  const [description, setDescription] = useState();
  const [files, setFiles] = useState();
  const [month, setMonth] = useState("1");
  const [annualBudget, setAnnualBudget] = useState("");
  const [budgetPrice, setBudgetPrice] = useState("");
  const [totalBudget, setTotalBudget] = useState("");
  const [quarterValues, setQuarterValues] = useState([]);
  const [productQuarterValues, setProductQuarterValues] = useState([]);
  const [attributesValues, setAttributesValues] = useState([]);
  const [productTotalValues, setProductTotalValues] = useState([]);
  // const [editProductTotalValues, setEditProductTotalValues] = useState([]);
  let [setMonthss, setSetMonthss] = useState([]);
  var monthsList = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  function monthListfunction() {
    let setMonthArr = [];
    //console.log("setMonth", month - 1, parseInt(month) + 2);
    for (let i = month; i < parseInt(month) + 3; i++) {
      setMonthArr.push(monthsList[i]);
    }
    // if (month == 10) {
    //   setMonthArr.pop();
    //   setMonthArr.push(monthsList[0]);
    // } else if (month == 11) {
    //   setMonthArr.pop();
    //   setMonthArr.pop();
    //   setMonthArr.push(monthsList[0], monthsList[1]);
    // } else if (month == 12) {
    //   for (let b = 0; 0 < setMonthArr.length; b++) {
    //     setMonthArr.pop();
    //   }
    //   setMonthArr.push(monthsList[0], monthsList[1], monthsList[2]);
    // }
    const undefinedFilter = setMonthArr.filter((item) => {
      return item == undefined;
    });
    const undefinedCount = undefinedFilter.length;
    if (undefinedCount.length !== 0) {
      let undefinedFill = [];
      for (let b = 0; b < undefinedCount; b++) {
        setMonthArr.pop();
        undefinedFill.push(monthsList[b]);
      }
      setMonthArr.splice(setMonthArr.length, 0, ...undefinedFill);
    }
    setSetMonthss(setMonthArr);
  }
  useMemo(() => {
    monthListfunction();
  }, [month]);

  //--------------- udpate budget------------
  function getAnnualBudgetPrice() {
    debugger;
    ForecastService.getAnnualBudgetPrice(productCode)
      .then((response) => {
        const data = response.data.data.annualBudget;
        setAnnualBudget(data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  //call based
  useEffect(() => {
    getAnnualBudgetPrice();
  }, [productCode]);

  // -------------- Values update logic for quarte new values--------------

  // Update the annual budget state
  const calculateQuarterTotal = (quarterValue) => {
    debugger;
    // console.log("quarterValues", quarterValues);
    // Calculate total value for a quarter by multiplying with budgetPrice
    return parseFloat(quarterValue) * (budgetPrice || 0);
  };
  const updateAnnualBudget = () => {
    debugger;
    // console.log("quarterValue", quarterValues);
    const newAnnualBudget = calculateQuarterTotal(quarterValues);
    const updatedAnnualBudget =
      isNaN(newAnnualBudget) || newAnnualBudget === undefined
        ? 0
        : newAnnualBudget;
    setProductQuarterValues(updatedAnnualBudget);
    // console.log("newAnnualBudget:", updatedAnnualBudget);
  };

  const handleQuarterChange = (e, index) => {
    debugger;
    // console.log("index", index);
    const newQuarterValues = [...quarterValues];
    newQuarterValues[index] = e.target.value;
    const productValues = newQuarterValues.map((quarter) => {
      const product = parseInt(quarter, 10) * budgetPrice;
      return product.toString();
    });
    // console.log("productValues", productValues);
    setQuarterValues(newQuarterValues);
    // console.log("newQuarterValues", newQuarterValues);
    // console.log("newQuarterValues", productValues);

    setProductTotalValues(productValues);
    // console.log("productTotalValues", productValues);

    // console.log("productValues_123", productValues);
    setAttributesValues(productValues);

    // console.log("productValues_1234", attributesValues);
    // console.log("quarterValues", quarterValues);
  };

  var jwtToken = localStorage.getItem("token");
  const jwtDecode = jwtToken ? jwt_decode(jwtToken) : null;
  const role = jwtDecode ? jwtDecode.role[0].name : null;
  const id = jwtDecode ? jwtDecode.Id : null;
  const alertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  //-------------Get principalList API------------
  // function getprincipalList() {
  //   console.log("categoriesGet");
  //   UserServices.searchPrinciple().then((respons) => {
  //     console.log("categoriesGet", respons.data.data);
  //     setPrincipalList(respons.data.data);
  //     //setPrincipalselect(respons.data.data[0].id);
  //   });
  // }

  const [assignedPrincipalList, setAssignedPrincipalList] = useState([]);

  //-------------Get principalList API------------
  function getAssignedPrincipalById() {
    DistributorServices.getAssignedPrincipalById(id).then((respons) => {
      setAssignedPrincipalList(respons.data.data);
      // setPrincipalselect(respons?.data?.data[0]?.id);

      // setPrincipalselect(respons.data.data[0].id);
    });
  }

  //-------------Get principalList API------------
  const [principals, setPrincipals] = useState([]);

  function getAllPrincipal() {
    AdminServices.getAllPrincipal().then((respons) => {
      setPrincipals(respons.data);
      // setPrincipalselect(respons?.data[0]?.id);
      // console.log("respons?.data[0]?.id)", respons?.data[0]?.id);
      // setPrincipalselect(respons.data.data[0].id);
    });
  }

  //-------------Get principalList API------------
  const [distributors, setDistributors] = useState([]);

  function getAllDistributor() {
    AdminServices.getAllDistributor().then((respons) => {
      setDistributors(respons.data);
      // setPrincipalselect(respons?.data[0]?.id);
      // console.log("respons?.data[0]?.id)", respons?.data[0]?.id);
      // setPrincipalselect(respons.data.data[0].id);
    });
  }
  //-------------Get Users API------------
  function getGellaryData(e) {
    debugger;
    if (e !== undefined) {
      // console.log("getUsers", e, page, pageSize);
      //   console.log("getUsers", page, pageSize)
      setPage(e.page == undefined ? 0 : e.page);
      setPageSize(e.pageSize == undefined ? 10 : e.pageSize);
    }
    setLoading(true);
    if (role == "ROLE_DISTRIBUTER") {
      ForecastService.distributerForecast(
        page,
        pageSize,
        orderStatus,
        forecastId,
        principalFilter,
        status,
        recentModified,
        startDate,
        endDate
      ).then((response) => {
        // console.log(response.data);
        const { forecast, totalPages, totalElements, data } =
          response.data.data;
        setGellaryData(forecast);
        // console.log("forecast_data", forecast);
        setTotalItems(totalElements);
        setCount(totalPages);
        setLoading(false);
      });
    } else if (role == "ROLE_ADMIN") {
      ForecastService.adminForecast(
        page,
        pageSize,
        orderStatus,
        forecastId,
        principalFilter,
        distributerFilter,
        status,
        recentModified,
        startDate,
        endDate
      ).then((response) => {
        // console.log(response.data);
        const { forecast, totalPages, totalElements, data } = response.data;
        setGellaryData(forecast);
        // console.log("admin-forecast:", forecast);
        setTotalItems(totalElements);
        setCount(totalPages);
        setLoading(false);
      });
    } else if (role == "ROLE_SUB_ADMIN") {
      ForecastService.subAdminForecast(
        page,
        pageSize,
        orderStatus,
        forecastId,
        principalFilter,
        distributerFilter,
        status,
        recentModified,
        startDate,
        endDate
      ).then((response) => {
        // console.log(response.data);
        const { forecast, totalPages, totalElements, data } = response.data;
        setGellaryData(forecast);
        // console.log("admin-forecast:", forecast);
        setTotalItems(totalElements);
        setCount(totalPages);
        setLoading(false);
      });
    } else {
      //principal forecast changes

      ForecastService.principalForecast(
        page,
        pageSize,
        orderStatus,
        forecastId,
        distributerFilter,
        status,
        recentModified,
        startDate,
        endDate
      ).then((response) => {
        const { totalPages, totalElements, data } = response;
        // console.log("forecast-resp", data);
        setGellaryData(data);
        // console.log("pr-forecast", response);
        setTotalItems(totalElements);
        setCount(1);
        setLoading(false);
      });
    }
  }

  useEffect(() => {
    getGellaryData();
    setFileCount(0);
    // getprincipalList();
    getAssignedPrincipalById();
    getAllDistributor();
    getAllPrincipal();

    var today = new Date();

    // Extract the components of the date
    var year = today.getFullYear();
    var month = (today.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based, so add 1
    var day = today.getDate().toString().padStart(2, "0");

    // Format the date as a string
    var formattedDate = year + "-" + month + "-" + day;
    setPaymentDate(formattedDate);
  }, []);

  //---------------------- product of quarter vales------------------------

  useEffect(() => {
    getGellaryData();
  }, [
    page,
    pageSize,
    orderStatus,
    forecastId,
    status,
    startDate,
    endDate,
    principalFilter,
    distributerFilter,
    recentModified,
  ]);
  //admin column

  const columns = [
    {
      field: "id",
      headerName: "Forecast Id",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "userPrincipal",
      headerName: "Principal",
      width: 120,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (param) => {
        return param.row.userPrincipal == null
          ? "null"
          : param.row.userPrincipal.name;
      },
    },
    {
      field: "productCode",
      headerName: "Product Code",
      width: 120,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "productName",
      headerName: "Product Name",
      width: 200,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "annualBudget",
      headerName: "Annual Budget",
      width: 130,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "month",
      headerName: "Month",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "forecastAttributes",
      headerName: "Quantity by Month",
      width: 150,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (param) => {
        const qtyList = param.row.forecastAttributes;
        return (
          <ul
            style={{
              listStyle: "none",
              padding: 0,
              margin: 0,
              display: "flex",
            }}
          >
            {qtyList.map((item) => (
              <li className="borderLine">{item.value}</li>
            ))}
          </ul>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 140,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (param) => {
        //console.log("prop.data d", param.row);
        return (
          <>
            <OrderStatus
              id={param?.row?.id}
              data={param?.row?.forecastStatuses}
              load={getGellaryData}
              role={role}
            />
          </>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (param) => {
        return (
          <>
            {" "}
            <Docread data={param.row} doc={param.row.productDocumentation} />
            {param.row.currentForecastStatus === "PENDING" ||
            param.row.currentForecastStatus === "REJECTED" ? (
              <>
                <button
                  type="button"
                  className="btn btn-primary  mx-1"
                  onClick={() => {
                    debugger;
                    setPrincipal(param.row.userPrincipal.id);
                    setProductCode(param.row.productCode);
                    setProductName(param.row.productName);
                    setMonth(monthsList.indexOf(param.row.month) + 1);
                    setBudgetPrice(param.row.price);
                    // console.log("setBudgetPrice", param.row.price);
                    setAnnualBudget(param.row.annualBudget);
                    setUpdateId(param.row.id);
                    let paramArr = [];
                    let paramTotalArr = [];
                    param.row.forecastAttributes.map((item) => {
                      paramArr.push(item.value);
                    });
                    // change
                    param.row.forecastAttributes.map((item) => {
                      paramTotalArr.push(item.amount);
                    });
                    setQuarterValues(paramArr);
                    setProductTotalValues(paramTotalArr);

                    // let paramArr_1 = [];
                    // param.row.attributesValues.map((item) => {
                    //   console.log("attributesValues", attributesValues);
                    //   paramArr_1.push(item.value);
                    // });
                    // getSeletedPrincipalProductList(param.row.userPrinciple.id);

                    updateHandleShow();
                  }}
                >
                  <i className="bi bi-pencil"></i>
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => deleteDistributorOrder(param.row.id)}
                >
                  <i className="bi bi-trash"></i>
                </button>
              </>
            ) : null}
            {/* //----------------------Read-Doc-----------------------------// */}
            {/* //----------------------Read-Doc-----------------------------// */}
          </>
        );
      },
    },
  ];
  const columnsPrincipal = [
    {
      field: "id",
      headerName: "Forecast Id",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },

    {
      field: "name",
      headerName: "File Name",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "comment",
      headerName: "Comment",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (param) => {
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: param?.row?.comment,
            }}
          ></div>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (param) => {
        return dayjs(param?.row?.createdAt).format("DD/MM/YYYY");
      },
    },
    {
      field: "budgetDocuments",
      headerName: "Download File",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (param) => {
        return (
          <button
            type="button"
            className="btn btn-primary mx-1"
            onClick={(e) =>
              handleDownloads(
                param?.row?.budgetDocuments[0]?.name,
                param?.row?.budgetDocuments[0]?.fileType,
                param?.row?.budgetDocuments[0]?.documentUrl
              )
            }
          >
            <i className="bi bi-download"></i>
          </button>
        );
      },
    },
  ];

  const handleDownloads = async (name, fileType, documentUrl) => {
    //const fileType = documentUrl.split(".")[4]
    try {
      const response = await fetch(documentUrl);
      const blob = await response.blob();
      console.error("downloading file:", documentUrl.split("."));
      // Creating a temporary link element
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${name}`; // Specify the filename for the downloaded file

      // Appending the link to the document body
      document.body.appendChild(link);

      // Triggering the download
      link.click();

      // Cleanup: remove the link element
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const columnsAdmin = [
    {
      field: "id",
      headerName: "Forecast Id",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      // renderCell: (params) => {
      //   return params.rowIndex + 1;
      // },
    },

    {
      field: "distributor",
      headerName: "Distributor",
      width: 130,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "userPrincipal",
      headerName: "Principal",
      width: 130,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (param) => {
        //console.log("user param", param.row);
        return param.row.userPrincipal == null
          ? "null"
          : param.row.userPrincipal.name;
      },
    },
    {
      field: "productCode",
      headerName: "Product Code",
      width: 130,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "productName",
      headerName: "Product Name",
      width: 130,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "month",
      headerName: "Month",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "annualBudget",
      headerName: "Annual Budget",
      width: 130,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "forecastAttributes",
      headerName: "Quantity by Month",
      width: 150,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (param) => {
        const qtyList = param.row.forecastAttributes;
        return (
          <ul
            style={{
              listStyle: "none",
              padding: 0,
              margin: 0,
              display: "flex",
            }}
          >
            {qtyList.map((item) => (
              <li
                style={{
                  borderRight: "1px solid rgba(224, 224, 224, 1)",
                  padding: "0 5px",
                  marginRight: "5px",
                }}
              >
                {item.value}
              </li>
            ))}
          </ul>
        );
      },
    },
    {
      field: "",
      headerName: "Status",
      width: 130,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (param) => {
        // console.log("prop.data d", param.row);
        return (
          <>
            <OrderStatus
              id={param.row.id}
              data={param.row.forecastStatuses}
              load={getGellaryData}
              role={role}
            />
          </>
        );
      },
    },
    {
      field: "status",
      headerName: "Is Approved?",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (param) => {
        // console.log("param Admin", param);
        function approved(e) {
          const formData = new FormData();
          formData.append("status", e);
          swal({
            title: "Are you sure?",
            text: `You want to Approve #${param.row.id} forecast`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              ForecastService.forecastApproved(formData, param.row.id).then(
                (response) => {
                  // console.log("response", response);
                  getGellaryData();

                  swal("Successfull", {
                    icon: "success",
                  });
                }
              );
            }
          });
        }
        return (
          <Switch
            checked={param.value}
            onChange={(e) => approved(e.target.checked)}
            disabled={param.value}
          />
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (param) => {
        //  console.log("prop.data d", param.row);
        return (
          <>
            {/* //----------------------Read-Doc-----------------------------// */}
            <Docread data={param.row} doc={param.row.productDocumentation} />
            {/* //----------------------Read-Doc-----------------------------// */}
            {/* <button type="button" className="btn btn-primary mx-1">
              <i className="bi bi-pencil"></i>
            </button>
            <button type="button" className="btn btn-primary ">
              <i className="bi bi-trash"></i>
            </button> */}
          </>
        );
      },
    },
  ];

  const deleteDistributorOrder = (orderId) => {
    swal({
      title: "Are you sure?",
      text: `You want to Delete #${orderId} Forecast`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        ForecastService.deleteForecast(orderId)
          .then((response) => {
            // console.log("Deleted Forecast", orderId);
            getGellaryData();
            swal("Successfull", {
              icon: "success",
            });
          })
          .catch((error) => {
            console.log("error", error);
          });
      }
    });
  };
  //-----------------Add Product----------------------------
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setBudgetPrice("");
    setAnnualBudget("");
    setProductName("");
    setQuarterValues("");
    setProductTotalValues("");
    setProductCode("");
    setShow(false);
  };

  const [validated, setValidated] = useState(false);
  //-----------------Add Product----------------------------
  const [updateshow, setUpdateshow] = useState(false);

  const updateHandleClose = () => {
    setBudgetPrice("");
    setAnnualBudget("");
    setProductName("");
    setQuarterValues("");
    setProductTotalValues("");
    setUpdateshow(false);
  };
  //---------------------Input- multi-File-Function--------------------
  let state = {};
  const handleShow = () => {
    debugger;

    setShow(true);
    setTimeout(() => {
      const inputFile = document.getElementById("multi-file-input");
      const fileList = document.getElementById("fileList");
      //  console.log("fileList", fileList.children[1])

      function updateState(newState) {
        state = { ...state, ...newState };
      }
      if (inputFile) {
        inputFile.addEventListener("change", function (e) {
          let files = inputFile.files;
          setFileCount(files.length);
          let filesArr = Array.from(files);

          updateState({ files: files, filesArr: filesArr });
          //    setFile(state)
          if (Object.keys(state).length !== 0) {
            const stateValue = state.filesArr;
            setFiles(stateValue);
          }
          renderFileList();
        });
      }
      let curArr;
      if (inputFile) {
        fileList.addEventListener("click", function (e) {
          let key = e.target.parentElement.getAttribute("key");

          if (state.filesArr !== undefined) {
            curArr = state.filesArr.slice();
            curArr.splice(key, 1);
            updateState({ filesArr: curArr });
            setFileCount(state.filesArr.length);
            const statefilesArr = state.filesArr;
            setFiles(statefilesArr);
            renderFileList();
          }
        });
      }

      function renderFileList() {
        let fileMap = state.filesArr.map((file, index) => {
          let suffix = "bytes";
          let size = file.size;
          if (size >= 1024 && size < 1024000) {
            suffix = "KB";
            size = Math.round((size / 1024) * 100) / 100;
          } else if (size >= 1024000) {
            suffix = "MB";
            size = Math.round((size / 1024000) * 100) / 100;
          }

          return `<li key="${index}"><span>${file.name} <span class="file-size"> | ${size} ${suffix}</span></span><i class="material-icons md-48"><img src="/assets/images/delete.png" style="width: 20px;height: auto;cursor: pointer;"/></i></li>`;
        });

        fileList.children[1].innerHTML = fileMap.join("");
      }
    }, [1000]);
  };
  //----------------------Downlaod Excel format-------------------------------
  // const handelDownload = ()=>{
  //    // Dummy data (Replace this with your actual data)
  //    const data = [
  //     ['Name', 'Age', 'Email'],
  //     ['John', 25, 'john@example.com'],
  //     ['Alice', 30, 'alice@example.com'],
  //     // Add more rows as needed
  //   ];

  //   // Create a new Excel workbook
  //   const workbook = XLSX.utils.book_new();
  //   const worksheet = XLSX.utils.aoa_to_sheet(data);

  //   // Add the worksheet to the workbook
  //   XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  //   // Generate a binary string from the workbook
  //   const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  //   const dataBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });

  //   // Create a temporary anchor element to trigger the download
  //   const downloadLink = document.createElement('a');
  //   downloadLink.href = URL.createObjectURL(dataBlob);
  //   downloadLink.download = 'data.xlsx'; // File name
  //   downloadLink.click();
  // }

  //----------------------updates-order--------------------------
  const updateHandleShow = () => {
    setUpdateshow(true);
    setTimeout(() => {
      const inputFile = document.getElementById("multi-file-input");
      const fileList = document.getElementById("fileList");
      //  console.log("fileList", fileList.children[1])

      function updateState(newState) {
        state = { ...state, ...newState };
        // console.log(state);
      }
      if (inputFile) {
        inputFile.addEventListener("change", function (e) {
          let files = inputFile.files;
          setFileCount(files.length);
          let filesArr = Array.from(files);

          updateState({ files: files, filesArr: filesArr });
          //    setFile(state)
          if (Object.keys(state).length !== 0) {
            const stateValue = state.filesArr;
            setFiles(stateValue);
            // console.log("formData", state.filesArr, files);
          }
          renderFileList();
        });
      }
      let curArr;
      if (inputFile) {
        fileList.addEventListener("click", function (e) {
          let key = e.target.parentElement.getAttribute("key");

          if (state.filesArr !== undefined) {
            curArr = state.filesArr.slice();
            curArr.splice(key, 1);
            updateState({ filesArr: curArr });
            setFileCount(state.filesArr.length);
            const statefilesArr = state.filesArr;
            setFiles(statefilesArr);
            // console.log("state dd", statefilesArr, files);
            renderFileList();
          }
        });
      }

      function renderFileList() {
        let fileMap = state.filesArr.map((file, index) => {
          let suffix = "bytes";
          let size = file.size;
          if (size >= 1024 && size < 1024000) {
            suffix = "KB";
            size = Math.round((size / 1024) * 100) / 100;
          } else if (size >= 1024000) {
            suffix = "MB";
            size = Math.round((size / 1024000) * 100) / 100;
          }

          return `<li key="${index}"><span>${file.name} <span class="file-size"> | ${size} ${suffix}</span></span><i class="material-icons md-48"><img src="/assets/images/delete.png" style="width: 20px;height: auto;cursor: pointer;"/></i></li>`;
        });

        fileList.children[1].innerHTML = fileMap.join("");
      }
    }, [1000]);
  };

  const editorConfiguration = {
    toolbar: {
      items: [
        "undo",
        "redo",
        "|",
        "heading",
        "|",
        "fontfamily",
        "fontsize",
        "fontColor",
        "fontBackgroundColor",
        "|",
        "bold",
        "italic",
        "strikethrough",
        "subscript",
        "superscript",
        "code",
        "|",
        "blockQuote",
        "|",
        "bulletedList",
        "numberedList",
        "todoList",
        "outdent",
        "indent",
      ],
      shouldNotGroupWhenFull: false,
      wordcount: {
        showCharCount: true,
      },
    },
  };
  //add Forecast
  function postAddPayment() {
    debugger;
    // console.log("formData", jwtDecode.Id);
    setLoad(true);
    var formData = new FormData();
    formData.append("principalId", principalselect);
    // console.log("principalselect", principal);
    formData.append("productCode", productCode);
    formData.append("productName", productName);
    formData.append("month", monthsList[month - 1]);
    formData.append("annualBudget", annualBudget);
    formData.append("price", budgetPrice);
    formData.append("forecastAttributesName", setMonthss);
    formData.append("forecastAttributesValue", quarterValues);
    formData.append("forecastProductValues", productTotalValues);

    // console.log("productTotalValues", productTotalValues);

    // Append files only if there are files present
    // if (files.length > 0) {
    //   for (let i = 0; i < files.length; i++) {
    //     formData.append("files", files[i]);
    //   }
    // }

    // formData.append("paymentDate", dayjs(paymentDate).format("YYYY/MM/DD"));
    formData.append("enquiryDetails", description);
    // formData.append("currency", currency);

    const formDataObject = {};

    // Iterate over the formData object and populate the formDataObject
    formData.forEach((value, key) => {
      formDataObject[key] = value;
    });

    formData.append("user", jwtDecode.Id);
    // console.log("formData", files, formData);

    // Now you can stringify formDataObject
    console.log(JSON.stringify(formDataObject));

    ForecastService.saveForecast(formData)
      .then((respons) => {
        setShow(false);
        setLoad(false);
        setMonth("1");
        setAnnualBudget("");
        setQuarterValues([]);
        setProductTotalValues([]); //change
        getGellaryData();
        swal({
          title: "Done",
          text: "Forecast has been Created",
          icon: "success",
          button: "Ok",
        });
      })
      .catch((e) => {
        console.log("err", e);
      });
  }
  //--------------------------update function-----------------
  function postUpdatePayment() {
    // const index = 0;
    // const e = 0;
    // handleQuarterChange(e, index);
    debugger;
    setLoad(true);
    var formData = new FormData();
    // formData.append("principalId", principalselect);
    formData.append("productCode", productCode);
    formData.append("productName", productName);

    formData.append("month", monthsList[month - 1]);
    formData.append("annualBudget", annualBudget);
    formData.append("price", budgetPrice);
    formData.append("forecastAttributesName", setMonthss);
    formData.append("forecastAttributesValue", quarterValues);
    formData.append("forecastProductValues", productTotalValues);
    // formData.append("forecastProductTotalValues", editProductTotalValues);
    // console.log("forecastProductValues", productTotalValues);

    // for (let i = 0; i < files.length; i++) {
    //   formData.append("files", files[i]);
    // }

    formData.append("user", jwtDecode.Id);
    formData.append("id", updateId);
    // console.log("formData", files, formData);

    ForecastService.ForecastUpdate(formData, principal)
      .then((respons) => {
        setUpdateshow(false);
        setUpdateId(null);
        setLoad(false);
        getGellaryData();
        swal({
          title: "Done",
          text: "Forecast has been Updated",
          icon: "success",
          button: "Ok",
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  const handleSubmit = (event) => {
    debugger;
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else if (
      productCode !== "" &&
      productName !== "" &&
      principalselect !== "" &&
      principalselect !== "Select Principal" &&
      month !== "" &&
      budgetPrice !== ""
    ) {
      debugger;
      postAddPayment();
      getGellaryData();
    } else {
      setOpen(true);
      setMessage("Please fill required field");
    }

    setValidated(true);
  };

  const [productCodeList, setProductCodeList] = useState([]);
  const [productData, setProductData] = useState([]);

  const getSeletedPrincipalProductList = (principalselect) => {
    debugger;
    UserServices.getSeletedPrincipalProductList(principalselect)
      .then((response) => {
        setProductCodeList(response.data);
        console.log("product_code_list", response.data);
        setProductName("");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSeletedPrincipalProductListEdit = (principal) => {
    debugger;
    UserServices.getSeletedPrincipalProductList(principal)
      .then((response) => {
        setProductCodeList(response.data);
        console.log("product_code_list", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProductByProductCode = (productCode) => {
    debugger;
    UserServices.getProductByProductCode(productCode)
      .then((response) => {
        debugger;
        setProductData(response.data);
        setProductName(response.data.name);
        // console.log("product_data", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    debugger;
    setProductName(productData?.name);
  }, [productData, productCode]);

  useEffect(() => {
    debugger;
    // Check if a principal is selected before making the API call
    // if (principalselect) {
    getSeletedPrincipalProductList(principalselect);
    // }
  }, [principalselect]);

  useEffect(() => {
    debugger;
    // Check if a principal is selected before making the API call
    // if (principalselect) {
    getSeletedPrincipalProductListEdit(principal);
    // }
  }, [principal]);

  useEffect(() => {
    debugger;
    // Check if a principal is selected before making the API call
    // if (productCode) {
    getProductByProductCode(productCode);
    // }
  }, [productCode]);

  console.log("productCode", productCode);

  return (
    <>
      <div className="row">
        <div className="col-12 col-lg-12 col-xl-12 d-flex">
          <div className="card w-100 rounded-4">
            <div className="card-body">
              <div className="row d-flex justify-content-between mb-2">
                <div class="col-lg-6">
                  <h3 class="mb-0 dash-heading">Forecast </h3>
                </div>
                <div class="col-lg-6 text-end">
                  {/* <FormControlLabel
                    control={
                      <Checkbox
                        value={recentModified}
                        onClick={(e) => setRecentModified(e.target.checked)}
                      />
                    }
                    label="Recently Updated"
                  /> */}
                  {/* button for new forecase */}
                  {role === "ROLE_DISTRIBUTER" ? (
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={handleShow}
                    >
                      <i className="fa fa-plus"></i> Add Forecast
                    </button>
                  ) : role === "ROLE_ADMIN" ? (
                    <UploadExcel
                      principalFilter={principalFilter}
                      pageType={"forecast"}
                      principals={principals}
                      principalselect={principalselect}
                    />
                  ) : null}
                </div>
              </div>
              <hr />
              <div className="row my-3">
                <div className="col-lg-12">
                  <div className="row">
                    {role == "ROLE_DISTRIBUTER" ? (
                      <div className="col">
                        <div className="form-group label-figma">
                          <Autocomplete
                            id="principalfilter"
                            sx={{ width: "100%" }}
                            options={assignedPrincipalList}
                            autoHighlight
                            getOptionLabel={(option) => option.name}
                            onChange={(event, value) => {
                              if (value) {
                                setPrincipalFilter(value.id);
                              } else {
                                setPrincipalFilter("");
                              }
                            }}
                            renderOption={(props, option) => (
                              <Box component="li" {...props}>
                                {option.name}
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Search by Principal"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "Search by Principal", // disable autocomplete and autofill
                                }}
                              />
                            )}
                          />
                        </div>
                      </div>
                    ) : role == "ROLE_CLIENT" ? null : role == "ROLE_ADMIN" ? (
                      <>
                        <div className="col">
                          <div className="form-group label-figma">
                            <Autocomplete
                              id="principalfilter"
                              sx={{ width: "100%" }}
                              options={principals}
                              autoHighlight
                              getOptionLabel={(option) => option.name}
                              onChange={(event, value) => {
                                if (value) {
                                  setPrincipalFilter(value.id);
                                } else {
                                  setPrincipalFilter("");
                                }
                              }}
                              renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                  {option.name}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Search by Principal"
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "Search by Principal", // disable autocomplete and autofill
                                  }}
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-group label-figma">
                            <Autocomplete
                              id="distirbutorfilters"
                              sx={{ width: "100%" }}
                              options={distributors}
                              autoHighlight
                              getOptionLabel={(option) => option.name}
                              onChange={(event, value) => {
                                if (value) {
                                  setDistributorFilter(value.id);
                                } else {
                                  setDistributorFilter("");
                                }
                              }}
                              renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                  {option.name}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Search by Distributor"
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "Search by Distributor", // disable autocomplete and autofill
                                  }}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </>
                    ) : null}
                    {role === "ROLE_DISTRIBUTER" || role === "ROLE_ADMIN" ? (
                      <>
                        <div className="col">
                          <div className="from-group label-figma">
                            <TextField
                              id="outlined-basic"
                              label="Search by Id"
                              variant="outlined"
                              value={forecastId}
                              onChange={(e) =>
                                setForecastIdFilter(e.target.value)
                              }
                              sx={{ width: "100%" }}
                            />
                          </div>
                        </div>

                        <div className="col">
                          <div className="form-group label-figma">
                            <FormControl sx={{ width: "100%" }}>
                              <InputLabel id="demo-multiple-name-label">
                                Forecast Status
                              </InputLabel>
                              <Select
                                labelId="demo-multiple-name-label"
                                id="demo-multiple-name"
                                value={orderStatus}
                                onChange={(e) => setOrderStatus(e.target.value)}
                                input={
                                  <OutlinedInput label="Forecast Status" />
                                }
                              >
                                <MenuItem key={""} value={""}>
                                  All
                                </MenuItem>
                                {statusList.map((name) => (
                                  <MenuItem key={name} value={name}>
                                    {name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </div>
                        </div>

                        <div className="col">
                          <div className="form-group label-figma">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                label="Start Date"
                                disableFuture
                                sx={{ width: "100%" }}
                                onChange={(newValue) => {
                                  if (newValue !== null) {
                                    // Parse the string date into a Date object
                                    const parsedDate = new Date(newValue);

                                    // Format the date as yyyy-mm-dd
                                    const year = parsedDate.getFullYear();
                                    const month = String(
                                      parsedDate.getMonth() + 1
                                    ).padStart(2, "0");
                                    const day = String(
                                      parsedDate.getDate()
                                    ).padStart(2, "0");
                                    const formattedDate = `${year}-${month}-${day}`;

                                    // Set the formatted date in your state or perform any other actions
                                    setStartDate(formattedDate);
                                  } else {
                                    // Handle the case where the date is null
                                    setStartDate(null);
                                  }
                                }}
                              />
                            </LocalizationProvider>
                          </div>
                        </div>

                        <div className="col">
                          <div className="form-group label-figma">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                label="End Date"
                                disableFuture
                                sx={{ width: "100%" }}
                                onChange={(newValue) => {
                                  if (newValue !== null) {
                                    // Parse the string date into a Date object
                                    const parsedDate = new Date(newValue);

                                    // Format the date as yyyy-mm-dd
                                    const year = parsedDate.getFullYear();
                                    const month = String(
                                      parsedDate.getMonth() + 1
                                    ).padStart(2, "0");
                                    const day = String(
                                      parsedDate.getDate()
                                    ).padStart(2, "0");
                                    const formattedDate = `${year}-${month}-${day}`;

                                    // Set the formatted date in your state or perform any other actions
                                    setEndDate(formattedDate);
                                  } else {
                                    // Handle the case where the date is null
                                    setEndDate(null);
                                  }
                                }}
                              />
                            </LocalizationProvider>
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>

              {/* roles are assigned here */}

              <div className="row my-3">
                <div className="row my-3">
                  {count > 0 ? (
                    gellaryData ? (
                      role === "ROLE_ADMIN" ||
                      role === "ROLE_DISTRIBUTER" ||
                      role === "ROLE_SUB_ADMIN" ? (
                        <ServerSideTable
                          pageSize={10}
                          page={0}
                          count={count}
                          functionName={getGellaryData}
                          data={gellaryData}
                          columns={
                            role === "ROLE_ADMIN" || role === "ROLE_SUB_ADMIN"
                              ? columnsAdmin
                              : role === "ROLE_DISTRIBUTER"
                              ? columns
                              : columnsPrincipal
                          }
                          loading={loading}
                          idname="id"
                        />
                      ) : (
                        <DataGrid
                          rows={gellaryData}
                          columns={columnsPrincipal}
                          hideFooterPagination
                          hideFooterSelectedRowCount
                          loading={loading}
                        />
                      )
                    ) : (
                      <Box
                        sx={{
                          height: 600,
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {loading ? (
                          <CircularProgress />
                        ) : (
                          <p>
                            <b>No Data Available</b>
                          </p>
                        )}
                      </Box>
                    )
                  ) : (
                    <Box
                      sx={{
                        height: 600,
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {loading ? (
                        <CircularProgress />
                      ) : (
                        <p>
                          <b>No Data Available</b>
                        </p>
                      )}
                    </Box>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ----Add forcast modal -----------*/}
      <Modal show={show} onHide={handleClose} size={"lg"} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Add New Forecast</Modal.Title>
        </Modal.Header>
        {load ? <LinearProgress /> : null}
        <Modal.Body>
          <Form noValidate validated={validated}>
            <Row className="mb-3">
              <Form.Group as={Col} md="4" controlId="validationCustom01">
                <Form.Label>
                  <b>Principal</b>
                </Form.Label>
                <Form.Select
                  required
                  // value={principalselect}
                  onChange={(e) => setPrincipalselect(e.target.value)}
                >
                  <option value="" selected>
                    Select Principal
                  </option>
                  {assignedPrincipalList?.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="validationCustom02">
                <Form.Label>
                  <b>Product Code</b>
                </Form.Label>
                <Form.Select
                  required
                  onChange={(e) => setProductCode(e.target.value)}
                >
                  <option value="">Select Product Code</option>
                  {productCodeList?.map((item) => (
                    <option value={item.productCode}>{item.productCode}</option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="validationCustom02">
                <Form.Label>
                  <b>Product Name</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  required
                  disabled
                  value={productName}
                  onChange={(e) => setProductName(e.target.value)}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="4" controlId="validationCustom01">
                <Form.Label>
                  <b>Month</b>
                </Form.Label>
                <Form.Select
                  onChange={(e) => {
                    setMonth(e.target.value);
                  }}
                >
                  <option disabled>Select Month</option>
                  {monthsList.map((item, i) => (
                    <option value={i + 1}>{item}</option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group as={Col} md="4" controlId="validationCustom02">
                <Form.Label>
                  <b>Price</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  required
                  value={budgetPrice}
                  onChange={(e) => {
                    setBudgetPrice(e.target.value);
                    updateAnnualBudget();
                  }}
                />
              </Form.Group>

              <Form.Group as={Col} md="4" controlId="validationCustom02">
                <Form.Label>
                  <b>Product Annual Budget</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  required
                  disabled
                  value={annualBudget}
                  onChange={(e) => setAnnualBudget(e.target.value)}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              {setMonthss.map((item, index) => (
                <Form.Group as={Col} md="4" controlId="validationCustom02">
                  <Form.Label>
                    <b>{item}</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    required
                    value={quarterValues[index]}
                    onChange={(e) => handleQuarterChange(e, index)}
                  />
                </Form.Group>
              ))}
            </Row>
            <Row className="mb-3">
              {setMonthss.map((item, index) => (
                <Form.Group
                  as={Col}
                  md="4"
                  controlId="validationCustom02"
                  key={index}
                >
                  <Form.Label>
                    <b>{item} Total Value</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    min=""
                    required
                    // value={productTotalValues[index] || 0}
                    value={
                      !isNaN(productTotalValues[index])
                        ? productTotalValues[index]
                        : 0
                    }
                  />
                </Form.Group>
              ))}
            </Row>

            {/* <Row>
              <Col className="mt-3">
                <div className="file-input">
                  <label className="file-input__label" for="file-input">
                    <input
                      type="file"
                      id="multi-file-input"
                      class="file-input__input"
                      multiple
                      //onChange={(e) => setFile(e.target.files)}
                    />
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="upload"
                      class="svg-inline--fa fa-upload fa-w-16"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
                      ></path>
                    </svg>
                    <span>Upload file</span>
                  </label>
                </div>
                <br />
                <div
                  className="files"
                  id="fileList"
                  style={
                    fileCount > 0 ? { display: "block" } : { display: "none" }
                  }
                >
                  <h5>Files Selected</h5>
                  <ul className="Filelisting"></ul>
                </div>
              </Col>
            </Row> */}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
      {/* /*---------------------------Update----------------------------------*/}
      <Modal
        show={updateshow}
        onHide={updateHandleClose}
        size={"lg"}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Forecast</Modal.Title>
        </Modal.Header>
        {load ? <LinearProgress /> : null}
        <Modal.Body>
          <Form noValidate validated={validated}>
            <Row className="mb-3">
              <Form.Group as={Col} md="4" controlId="validationCustom01">
                <Form.Label>
                  <b>Principal</b>
                </Form.Label>
                <Form.Select
                  onChange={(e) => setPrincipalselect(e.target.value)}
                >
                  <option disabled>Select Principal</option>
                  {assignedPrincipalList?.map((item) => (
                    <option
                      value={item.id}
                      selected={item.id == principal ? true : false}
                    >
                      {item.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="validationCustom02">
                <Form.Label>
                  <b>Product Code</b>
                </Form.Label>
                <Form.Select onChange={(e) => setProductCode(e.target.value)}>
                  {productCodeList?.map((item) => (
                    <option
                      value={item.productCode}
                      selected={item.productCode === productCode ? true : false}
                    >
                      {item.productCode}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="validationCustom02">
                <Form.Label>
                  <b>Product Name</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  required
                  disabled
                  value={productName}
                  onChange={(e) => setProductName(e.target.value)}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="4" controlId="validationCustom01">
                <Form.Label>
                  <b>Month</b>
                </Form.Label>
                <Form.Select onChange={(e) => setMonth(e.target.value)}>
                  <option disabled>Select Currency</option>
                  {monthsList.map((item, i) => (
                    <option
                      value={i + 1}
                      selected={
                        month == 0
                          ? i == month
                          : month !== 0
                          ? i == month - 1
                          : null
                      }
                    >
                      {item}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="validationCustom02">
                <Form.Label>
                  <b>Price</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  required
                  value={budgetPrice}
                  onChange={(e) => setBudgetPrice(e.target.value)}
                  // onBlur={(e) => handleQuarterChange(e, index)}
                />
              </Form.Group>

              <Form.Group as={Col} md="4" controlId="validationCustom02">
                <Form.Label>
                  <b>Product Annual Budget</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  required
                  disabled
                  value={annualBudget}
                  onChange={(e) => setAnnualBudget(e.target.value)}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              {setMonthss.map((item, index) => (
                <Form.Group as={Col} md="4" controlId="validationCustom02">
                  <Form.Label>
                    <b>{item}</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    required
                    value={quarterValues[index]}
                    onChange={(e) => handleQuarterChange(e, index)}
                  />
                </Form.Group>
              ))}
            </Row>

            <Row className="mb-3">
              {setMonthss.map((item, index) => (
                <Form.Group as={Col} md="4" controlId="validationCustom02">
                  <Form.Label>
                    <b>{item} Total Value</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    required
                    disabled
                    value={
                      !isNaN(productTotalValues[index])
                        ? productTotalValues[index]
                        : 0
                    }
                  />
                </Form.Group>
              ))}
            </Row>
            {/* <Row>
              <Col className="mt-3">
                <div className="file-input">
                  <label className="file-input__label" for="file-input">
                    <input
                      type="file"
                      id="multi-file-input"
                      class="file-input__input"
                      multiple
                      //onChange={(e) => setFile(e.target.files)}
                    />
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="upload"
                      class="svg-inline--fa fa-upload fa-w-16"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
                      ></path>
                    </svg>
                    <span>Upload file</span>
                  </label>
                </div>
                <br />
                <div
                  className="files"
                  id="fileList"
                  style={
                    fileCount > 0 ? { display: "block" } : { display: "none" }
                  }
                >
                  <h5>Files Selected</h5>
                  <ul className="Filelisting"></ul>
                </div>
              </Col>
            </Row> */}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={updateHandleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={postUpdatePayment}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={5000}
          onClose={alertClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={alertClose} severity="error" sx={{ width: "100%" }}>
            {message}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
}

export default Forecast;
