import { useEffect, useState } from "react";
import UserServices from "../../services/UserServices";
import ServerSideTable from "../serverSideTable";
import CircularProgress from "@mui/material/CircularProgress";
import UploadExcel from "../uploadExcel";
import http from "../../httpcommon";
import { toast } from "react-toastify";
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "../style.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import * as React from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import jwt_decode from "jwt-decode";
import Docread from "./docread";
import OrderStatus from "./orderStatus";
import DistributorServices from "../../services/DistributorServices";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import swal from "sweetalert";
import AdminServices from "../../services/AdminServices";
import MoreMenu from "./moreMenu";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Order() {
  const [gellaryData, setGellaryData] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState("");
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [fileCount, setFileCount] = useState(0);
  const [load, setLoad] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [principalList, setPrincipalList] = useState([]);
  const [principalselect, setPrincipalselect] = useState("");
  const [status, setStatus] = useState("");
  const [orderStatus, setOrderStatus] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [orderIdFilter, setOrderIdFilter] = useState("");
  const [principalFilter, setPrincipalFilter] = useState("");
  const [distributerFilter, setDistributorFilter] = useState("");
  const [recentModified, setRecentModified] = useState(false);
  const [baseUrls, setBaseUrls] = useState();

  useEffect(() => {
    const baseURL = http.defaults.baseURL;
    setBaseUrls(http.defaults.baseURL);
  }, []);

  const statusList = [
    "PENDING",
    "REJECTED",
    "APPROVED",
    "PRODUCTION",
    "SHIPPED",
    "DELIVERED",
  ];
  useEffect(() => {}, [
    orderStatus,
    orderIdFilter,
    principalFilter,
    distributerFilter,
    status,
    startDate,
    endDate,
    recentModified,
  ]);

  //Add product Form Data
  const [updateId, setUpdateId] = useState();
  const [principal, setPrincipal] = useState("");

  const [incoTerms, setIncoTerms] = useState("");
  const [freight, setFreight] = useState("");
  const [insurance, setInsurance] = useState("");
  const [paymentTerms, setPaymentTerms] = useState("");
  const [poNumber, setPoNumber] = useState("");
  const [invoice, setInvoice] = useState("");
  const [description, setDescription] = useState("");
  const [files, setFiles] = useState();

  var jwtToken = localStorage.getItem("token");
  const jwtDecode = jwtToken ? jwt_decode(jwtToken) : null;
  const role = jwtDecode ? jwtDecode.role[0].name : null;
  const id = jwtDecode ? jwtDecode.Id : null;
  const alertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  //-------------Get principalList API------------

  const [assignedPrincipalList, setAssignedPrincipalList] = useState([]);

  //-------------Get principalList API------------
  function getAssignedPrincipalById() {
    DistributorServices.getAssignedPrincipalById(id).then((respons) => {
      setAssignedPrincipalList(respons.data.data);
    });
  }

  //-------------Get principalList API------------
  const [principals, setPrincipals] = useState([]);

  function getAllPrincipal() {
    AdminServices.getAllPrincipal().then((respons) => {
      setPrincipals(respons.data);
    });
  }

  //-------------Get principalList API------------
  const [distributors, setDistributors] = useState([]);

  function getAllDistributor() {
    AdminServices.getAllDistributor().then((respons) => {
      setDistributors(respons.data);
    });
  }
  //-------------Get Users API------------
  function getGellaryData(e) {
    if (e !== undefined) {
      setPage(e.page == undefined ? 0 : e.page);
      setPageSize(e.pageSize == undefined ? 10 : e.pageSize);
    }
    setLoading(true);
    if (role == "ROLE_DISTRIBUTER") {
      UserServices.orderGet(
        page,
        pageSize,
        orderStatus,
        orderIdFilter,
        principalFilter,
        status,
        startDate,
        endDate,
        recentModified
      ).then((response) => {
        const { orders, totalPages, totalElements, data } = response.data.data;
        setGellaryData(orders);
        setTotalItems(totalElements);
        setCount(totalPages);
        setLoading(false);
      });
    } else if (role == "ROLE_ADMIN") {
      UserServices.adminOrderGet(
        page,
        pageSize,
        orderStatus,
        orderIdFilter,
        principalFilter,
        distributerFilter,
        status,
        startDate,
        endDate,
        recentModified
      ).then((response) => {
        const { content, totalPages, totalElements, data } = response.data;
        setGellaryData(content);
        setTotalItems(totalElements);
        setCount(totalPages);
        setLoading(false);
      });
    } else if (role == "ROLE_SUB_ADMIN") {
      UserServices.subadminOrderGet(
        page,
        pageSize,
        orderStatus,
        orderIdFilter,
        principalFilter,
        distributerFilter,
        status,
        startDate,
        endDate,
        recentModified
      ).then((response) => {
        const { content, totalPages, totalElements, data } = response.data;
        setGellaryData(content);
        setTotalItems(totalElements);
        setCount(totalPages);
        setLoading(false);
      });
    } else {
      UserServices.approvedOrderPrincipal(
        page,
        pageSize,
        orderStatus,
        orderIdFilter,
        distributerFilter,
        status,
        startDate,
        endDate,
        recentModified
      ).then((response) => {
        const { orders, totalPages, totalElements, data } = response.data.data;
        setGellaryData(orders);
        setTotalItems(totalElements);
        setCount(totalPages);
        setLoading(false);
      });
    }
  }
  useEffect(() => {
    getGellaryData();
    setFileCount(0);
    getAssignedPrincipalById();
    getAllDistributor();
    getAllPrincipal();
  }, []);
  useEffect(() => {
    getGellaryData();
  }, [
    page,
    pageSize,
    orderStatus,
    orderIdFilter,
    principalFilter,
    distributerFilter,
    status,
    startDate,
    endDate,
    recentModified,
  ]);

  const columns = [
    {
      field: "id",
      headerName: "Order Id",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "poNumber",
      headerName: "PO Number",
      width: 130,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "userPrincipal",
      headerName: "Principal",
      width: 130,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (param) => {
        return param.row.userPrincipal == null
          ? "null"
          : param.row.userPrincipal.name;
      },
    },
    {
      field: "paymentTerms",
      headerName: "Payment Terms",
      width: 140,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "incoTerms",
      headerName: "Inco Terms",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "freight",
      headerName: "Freight",
      width: 90,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "insurance",
      headerName: "Insurance",
      width: 90,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },

    {
      field: "status",
      headerName: "Status",
      width: 130,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (param) => {
        return (
          <>
            <OrderStatus
              id={param?.row?.id}
              data={param?.row?.orderStatus}
              load={getGellaryData}
              role={role}
            />
          </>
        );
      },
    },
    {
      field: "More",
      width: 60,
      //  GridAlignment: "center",
      headerAlign: "center",
      renderCell: (param) => {
        return (
          <>
            <MoreMenu
              id={param.row.id}
              principalName={param.row.userPrincipal.name}
              principalId={param.row.userPrincipal.id}
            />
            {/* <Link to={`/dashboard/complaint`} state={{ orderID: param.row.id, modelPopUp: true }}>
              <Tooltip title={`Complaint for Order ID ${param.row.id}`} arrow><button type="button" className="btn btn-primary" ><i className="bi bi-question-circle"></i></button></Tooltip>
            </Link>
            <Link to={`/dashboard/payment`} state={{ orderID: param.row.id, modelPopUp: true }}>
              <Tooltip title={`Payment for Order ID ${param.row.id}`} arrow><button type="button" className="btn btn-primary mx-1" ><i className="bi bi-credit-card"></i></button></Tooltip>
            </Link>
            <Link to={`/dashboard/dcnote`} state={{ orderID: param.row.id, modelPopUp: true }}>
              <Tooltip title={`Debit/Credit Note for Order ID ${param.row.id}`} arrow><button type="button" className="btn btn-primary" ><i className="bi bi-card-list"></i></button></Tooltip>
            </Link> */}
          </>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (param) => {
        return (
          <>
            {" "}
            <Docread data={param.row} doc={param.row.productDocumentation} />
            {param.row.currentOrderStatus === "PENDING" ||
            param.row.currentOrderStatus === "REJECTED" ? (
              <>
                <button
                  type="button"
                  className="btn btn-primary  mx-1"
                  onClick={() => {
                    setPrincipal(param.row.userPrincipal.id);
                    setIncoTerms(param.row.incoTerms);
                    setFreight(param.row.freight);
                    setInsurance(param.row.insurance);
                    setPaymentTerms(param.row.paymentTerms);
                    setPoNumber(param.row.poNumber);
                    setInvoice(param.row.invoice);
                    setDescription(param.row.specialRequirement);
                    setUpdateId(param.row.id);
                    updateHandleShow();
                  }}
                >
                  <i className="bi bi-pencil"></i>
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => deleteDistributorOrder(param.row.id)}
                >
                  <i className="bi bi-trash"></i>
                </button>
              </>
            ) : null}
            {/* //----------------------Read-Doc-----------------------------// */}
            {/* //----------------------Read-Doc-----------------------------// */}
          </>
        );
      },
    },
  ];
  const columnsPrincipal = [
    {
      field: "id",
      headerName: "Order Id",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "poNumber",
      headerName: "PO Number",
      width: 120,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "distributer",
      headerName: "Distributor",
      width: 130,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },

    {
      field: "paymentTerms",
      headerName: "Payment Terms",
      width: 130,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "incoTerms",
      headerName: "Inco Terms",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "freight",
      headerName: "Freight",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "insurance",
      headerName: "Insurance",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (param) => {
        return (
          <>
            <OrderStatus
              id={param.row.id}
              data={param.row.orderStatus}
              load={getGellaryData}
              role={role}
            />
          </>
        );
      },
    },

    // {
    //     field: "status",
    //     headerName: "status",
    //     flex: 1,
    //     headerClassName: "super-app-theme--header",
    //     headerAlign: "left",
    // },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (param) => {
        return (
          <>
            {/* //----------------------Read-Doc-----------------------------// */}
            <Docread data={param.row} doc={param.row.productDocumentation} />
            {/* //----------------------Read-Doc-----------------------------// */}
          </>
        );
      },
    },
  ];

  const columnsSubAdmin = [
    {
      field: "id",
      headerName: "Order Id",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "poNumber",
      headerName: "PO Number",
      width: 120,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "distributer",
      headerName: "Distributor",
      width: 130,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "userPrincipal",
      headerName: "Principal",
      width: 150,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (param) => {
        return param.row.userPrincipal == null
          ? "null"
          : param.row.userPrincipal.name;
      },
    },
    {
      field: "paymentTerms",
      headerName: "Payment Terms",
      width: 130,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "incoTerms",
      headerName: "Inco Terms",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "freight",
      headerName: "Freight",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "insurance",
      headerName: "Insurance",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (param) => {
        return (
          <>
            <OrderStatus
              id={param.row.id}
              data={param.row.orderStatus}
              load={getGellaryData}
              role={role}
            />
          </>
        );
      },
    },

    // {
    //     field: "status",
    //     headerName: "status",
    //     flex: 1,
    //     headerClassName: "super-app-theme--header",
    //     headerAlign: "left",
    // },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (param) => {
        return (
          <>
            {/* //----------------------Read-Doc-----------------------------// */}
            <Docread data={param.row} doc={param.row.productDocumentation} />
            {/* //----------------------Read-Doc-----------------------------// */}
          </>
        );
      },
    },
  ];

  const columnsAdmin = [
    {
      field: "id",
      headerName: "Order Id",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      onClick: () => alert("ID"),
    },
    {
      field: "poNumber",
      headerName: "PO Number",
      width: 130,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "distributer",
      headerName: "Distributor",
      width: 150,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "userPrincipal",
      headerName: "Principal",
      width: 150,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (param) => {
        return param.row.userPrincipal == null
          ? "null"
          : param.row.userPrincipal.name;
      },
    },
    {
      field: "paymentTerms",
      headerName: "Payment Terms",
      width: 150,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "incoTerms",
      headerName: "Inco Terms",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "freight",
      headerName: "Freight",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "insurance",
      headerName: "Insurance",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
    },
    {
      field: "",
      headerName: "Status",
      width: 130,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (param) => {
        return (
          <>
            <OrderStatus
              id={param.row.id}
              data={param.row.orderStatus}
              load={getGellaryData}
              role={role}
            />
          </>
        );
      },
    },
    {
      field: "status",
      headerName: "Is Approved?",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (param) => {
        function approved(e) {
          const formData = new FormData();

          formData.append("status", e);
          swal({
            title: "Are you sure?",
            text: `You want to Approve #${param.row.id} Order`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              UserServices.orderApproved(formData, param.row.id).then(
                (response) => {
                  getGellaryData();
                  swal("Successfull", {
                    icon: "success",
                  });
                }
              );
            }
          });
        }
        return (
          <Switch
            checked={param.value}
            onChange={(e) => approved(e.target.checked)}
            disabled={param.value}
          />
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (param) => {
        return (
          <>
            {/* //----------------------Read-Doc-----------------------------// */}
            <Docread
              data={param.row}
              doc={param.row.productDocumentation}
              getFunction={getGellaryData}
            />
            {/* //----------------------Read-Doc-----------------------------// */}
            {/* <button type="button" className="btn btn-primary mx-1">
              <i className="bi bi-pencil"></i>
            </button>
            <button type="button" className="btn btn-primary ">
              <i className="bi bi-trash"></i>
            </button> */}
          </>
        );
      },
    },
  ];

  const deleteDistributorOrder = (orderId) => {
    swal({
      title: "Are you sure?",
      text: `You want to Delete #${orderId} Order`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        UserServices.deleteDistributorOrder(orderId)
          .then((response) => {
            getGellaryData();
            swal("Successfully", {
              icon: "success",
            });
          })
          .catch((error) => {
            console.log("error", error);
          });
      }
    });
  };
  //-----------------Add Product----------------------------
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const [validated, setValidated] = useState(false);
  //-----------------Add Product----------------------------
  const [updateshow, setUpdateshow] = useState(false);
  const ExcelType = "order";

  const updateHandleClose = () => setUpdateshow(false);
  //---------------------Input- multi-File-Function--------------------
  let state = {};
  const handleShow = () => {
    setDescription("");
    setShow(true);
    setTimeout(() => {
      const inputFile = document.getElementById("multi-file-input");
      const fileList = document.getElementById("fileList");

      function updateState(newState) {
        state = { ...state, ...newState };
      }
      if (inputFile) {
        inputFile.addEventListener("change", function (e) {
          let files = inputFile.files;
          setFileCount(files.length);
          let filesArr = Array.from(files);

          updateState({ files: files, filesArr: filesArr });
          //    setFile(state)
          if (Object.keys(state).length !== 0) {
            const stateValue = state.filesArr;
            setFiles(stateValue);
          }
          renderFileList();
        });
      }
      let curArr;
      if (inputFile) {
        fileList.addEventListener("click", function (e) {
          let key = e.target.parentElement.getAttribute("key");

          if (state.filesArr !== undefined) {
            curArr = state.filesArr.slice();
            curArr.splice(key, 1);
            updateState({ filesArr: curArr });
            setFileCount(state.filesArr.length);
            const statefilesArr = state.filesArr;
            setFiles(statefilesArr);
            renderFileList();
          }
        });
      }

      function renderFileList() {
        let fileMap = state.filesArr.map((file, index) => {
          let suffix = "bytes";
          let size = file.size;
          if (size >= 1024 && size < 1024000) {
            suffix = "KB";
            size = Math.round((size / 1024) * 100) / 100;
          } else if (size >= 1024000) {
            suffix = "MB";
            size = Math.round((size / 1024000) * 100) / 100;
          }

          return `<li key="${index}"><span>${file.name} <span class="file-size"> | ${size} ${suffix}</span></span><i class="material-icons md-48"><img src="/assets/images/delete.png" style="width: 20px;height: auto;cursor: pointer;"/></i></li>`;
        });

        fileList.children[1].innerHTML = fileMap.join("");
      }
    }, [1000]);
  };

  //----------------------template download functionlity------------------

  const downloadTemplate = () => {
    try {
      setLoading(true);
      downloadExcel(ExcelType);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error during download:", error);
    }
  };

  const downloadExcel = async (ExcelType) => {
    try {
      const baseURL1 = http.defaults.baseURL;

      // Make an API request to get the template information
      const response = await fetch(`${baseURL1}admin/downloadtemplates`);

      if (!response.ok) {
        throw new Error(
          `Failed to fetch template information: ${response.statusText}`
        );
      }

      // Extract the template information from the API response
      const templateInfo = await response.json();

      // Determine the template type and document URL based on your data
      const { templateType, documentUrl } = determineTemplateInfo(
        templateInfo,
        ExcelType
      );

      if (!documentUrl) {
        toast.error(`Template for ${ExcelType} not found.`);
        return;
      }

      // Make another API request to download the actual file
      const fileResponse = await fetch(documentUrl);
      const blob = await fileResponse.blob();

      // Creating a temporary link element
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${ExcelType}_template.xlsx`; // Use ExcelType for the file name
      // Appending the link to the document body
      document.body.appendChild(link);
      // Triggering the auto download
      link.click();
      // Cleanup: remove the link element
      document.body.removeChild(link);

      toast.success("File download successful");
    } catch (error) {
      console.error("Error while downloading template:", error);
      toast.error("Error while downloading template");
    }
  };

  // Function to determine the template type and document URL based on your data structure and ExcelType
  const determineTemplateInfo = (templateInfo, ExcelType) => {
    switch (ExcelType) {
      case "sales":
        return {
          templateType: "SALESPIPELINE_TEMPLATE",
          documentUrl:
            templateInfo.SALESPIPELINE_TEMPLATE?.templateDocument?.documentUrl,
        };
      case "order":
        return {
          templateType: "ORDER_TEMPLATE",
          documentUrl:
            templateInfo.ORDER_TEMPLATE?.templateDocument?.documentUrl,
        };
      case "sample":
        return {
          templateType: "SAMPLE_TEMPLATE",
          documentUrl:
            templateInfo.SAMPLE_TEMPLATE?.templateDocument?.documentUrl,
        };
      default:
        const defaultTemplate = templateInfo[ExcelType];
        if (!defaultTemplate) {
          return {
            templateType: null,
            documentUrl: null,
          };
        }
        return {
          templateType: ExcelType,
          documentUrl: defaultTemplate?.templateDocument?.documentUrl,
        };
    }
  };

  //----------------------updates-order--------------------------
  const updateHandleShow = () => {
    setUpdateshow(true);
    setTimeout(() => {
      const inputFile = document.getElementById("multi-file-input");
      const fileList = document.getElementById("fileList");

      function updateState(newState) {
        state = { ...state, ...newState };
      }
      if (inputFile) {
        inputFile.addEventListener("change", function (e) {
          let files = inputFile.files;
          setFileCount(files.length);
          let filesArr = Array.from(files);

          updateState({ files: files, filesArr: filesArr });
          if (Object.keys(state).length !== 0) {
            const stateValue = state.filesArr;
            setFiles(stateValue);
          }
          renderFileList();
        });
      }
      let curArr;
      if (inputFile) {
        fileList.addEventListener("click", function (e) {
          let key = e.target.parentElement.getAttribute("key");

          if (state.filesArr !== undefined) {
            curArr = state.filesArr.slice();
            curArr.splice(key, 1);
            updateState({ filesArr: curArr });
            setFileCount(state.filesArr.length);
            const statefilesArr = state.filesArr;
            setFiles(statefilesArr);
            renderFileList();
          }
        });
      }

      function renderFileList() {
        let fileMap = state.filesArr.map((file, index) => {
          let suffix = "bytes";
          let size = file.size;
          if (size >= 1024 && size < 1024000) {
            suffix = "KB";
            size = Math.round((size / 1024) * 100) / 100;
          } else if (size >= 1024000) {
            suffix = "MB";
            size = Math.round((size / 1024000) * 100) / 100;
          }

          return `<li key="${index}"><span>${file.name} <span class="file-size"> | ${size} ${suffix}</span></span><i class="material-icons md-48"><img src="/assets/images/delete.png" style="width: 20px;height: auto;cursor: pointer;"/></i></li>`;
        });

        fileList.children[1].innerHTML = fileMap.join("");
      }
    }, [1000]);
  };

  const editorConfiguration = {
    toolbar: {
      items: [
        "undo",
        "redo",
        "|",
        "heading",
        "|",
        "fontfamily",
        "fontsize",
        "fontColor",
        "fontBackgroundColor",
        "|",
        "bold",
        "italic",
        "strikethrough",
        "subscript",
        "superscript",
        "code",
        "|",
        "blockQuote",
        "|",
        "bulletedList",
        "numberedList",
        "todoList",
        "outdent",
        "indent",
      ],
      shouldNotGroupWhenFull: false,
      wordcount: {
        showCharCount: true,
      },
    },
  };

  //-------------------Add product----------
  function postAddProduct() {
    if (
      paymentTerms !== "" &&
      poNumber !== "" &&
      incoTerms !== "" &&
      freight !== "" &&
      insurance !== "" &&
      description !== "" &&
      invoice !== "" &&
      principalselect !== ""
    ) {
      setLoad(true);
      var formData = new FormData();
      formData.append("paymentTerms", paymentTerms);
      formData.append("poNumber", poNumber);
      formData.append("incoTerms", incoTerms);
      formData.append("freight", freight);

      if (files && files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          formData.append("files", files[i]);
        }
      } else {
        formData.append("files", null);
      }

      formData.append("insurance", insurance);
      formData.append("SpecialRequirement", description);
      formData.append("invoice", invoice);
      formData.append("id", principalselect);
      formData.append("user", jwtDecode.Id);

      const formDataObject = {};

      // Iterate over the formData object and populate the formDataObject
      formData.forEach((value, key) => {
        formDataObject[key] = value;
      });

      // Now you can stringify formDataObject
      // console.log(JSON.stringify(formDataObject));

      UserServices.newOrder(formData)
        .then((respons) => {
          setShow(false);
          setLoad(false);
          setPrincipalselect("");
          setPoNumber("");
          setDescription("");
          getGellaryData();
          swal({
            title: "Done",
            text: "New Order has been Created",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((e) => {
          console.log("err", e);
          swal({
            title: "Error!",
            text: "Something went wrong.",
            icon: "error", // 'error' will show a danger alert
            button: "OK",
          });
        });
    } else {
      setOpen(true);
    }
  }

  // -----Update Order-----------------

  function postUpdateProduct() {
    if (poNumber !== "") {
      var formData = new FormData();
      formData.append("paymentTerms", paymentTerms);
      formData.append("poNumber", poNumber);
      formData.append("incoTerms", incoTerms);
      formData.append("freight", freight);

      if (files && files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          formData.append("files", files[i]);
        }
      } else {
        formData.append("files", null);
      }

      formData.append("insurance", insurance);
      formData.append("SpecialRequirement", description);
      formData.append("invoice", invoice);
      formData.append("id", principal);
      formData.append("user", jwtDecode.Id);
      const formDataObject = {};

      // Iterate over the formData object and populate the formDataObject
      formData.forEach((value, key) => {
        formDataObject[key] = value;
      });

      // // Now you can stringify formDataObject
      // console.log(JSON.stringify(formDataObject));

      UserServices.updateOrder(formData, updateId)
        .then((response) => {
          setUpdateshow(false);
          setUpdateId(null);
          setLoad(false);
          getGellaryData();
          swal({
            title: "Done",
            text: "Order has been Updated",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((e) => {
          console.log(e);
          swal({
            title: "Error!",
            text: "Something went wrong.",
            icon: "error", // 'error' will show a danger alert
            button: "OK",
          });
        });
    } else {
      setOpen(true);
    }
  }

  const updatehandleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      postUpdateProduct();
      getGellaryData();
    }

    setValidated(true);
  };
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      postAddProduct();
      getGellaryData();
    }

    setValidated(true);
  };

  return (
    <>
      <div className="row">
        <div className="col-12 col-lg-12 col-xl-12 d-flex">
          <div className="card w-100 rounded-4">
            <div className="card-body">
              <div className="row d-flex justify-content-between mb-2">
                <div class="col-lg-5">
                  <h3 class="mb-0 dash-heading">Orders</h3>
                </div>
                <div class="col-lg-7 d-flex justify-content-end align-items-center">
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={recentModified}
                        onClick={(e) => setRecentModified(e.target.checked)}
                      />
                    }
                    label="Recently Updated"
                  />
                  {role === "ROLE_DISTRIBUTER" && (
                    <button
                      type="button"
                      class="btn btn-success"
                      onClick={handleShow}
                    >
                      <i class="fa fa-plus"></i> Create Order
                    </button>
                  )}
                  {role === "ROLE_ADMIN" && (
                    <UploadExcel
                      principalFilter={principalFilter}
                      principals={principals}
                      pageType="order"
                      principalselect={principalselect}
                      visiblity="download"
                    />
                  )}
                </div>
              </div>
              <hr />
              <div className="row my-3">
                <div className="col-lg-12">
                  <div className="row">
                    {role == "ROLE_DISTRIBUTER" ? (
                      <div className="col">
                        <div className="form-group label-figma">
                          <Autocomplete
                            id="principalfilter"
                            sx={{ width: "100%" }}
                            options={assignedPrincipalList}
                            autoHighlight
                            getOptionLabel={(option) => option.name}
                            onChange={(event, value) => {
                              if (value) {
                                setPrincipalFilter(value.id);
                              } else {
                                setPrincipalFilter("");
                              }
                            }}
                            renderOption={(props, option) => (
                              <Box component="li" {...props}>
                                {option.name}
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Search by Principal"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "Search by Principal", // disable autocomplete and autofill
                                }}
                              />
                            )}
                          />
                        </div>
                      </div>
                    ) : role == "ROLE_CLIENT" ? (
                      <div className="col">
                        <div className="form-group label-figma">
                          <Autocomplete
                            id="principalfilter"
                            sx={{ width: "100%" }}
                            options={assignedPrincipalList}
                            autoHighlight
                            getOptionLabel={(option) => option.name}
                            onChange={(event, value) => {
                              if (value) {
                                setDistributorFilter(value.id);
                              } else {
                                setDistributorFilter("");
                              }
                            }}
                            renderOption={(props, option) => (
                              <Box component="li" {...props}>
                                {option.name}
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Search by Distributor"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "Search by Distributor", // disable autocomplete and autofill
                                }}
                              />
                            )}
                          />
                        </div>
                      </div>
                    ) : role == "ROLE_ADMIN" ? (
                      <>
                        <div className="col">
                          <div className="form-group label-figma">
                            <Autocomplete
                              id="principalfilter"
                              sx={{ width: "100%" }}
                              options={principals}
                              autoHighlight
                              getOptionLabel={(option) => option.name}
                              onChange={(event, value) => {
                                if (value) {
                                  setPrincipalFilter(value.id);
                                } else {
                                  setPrincipalFilter("");
                                }
                              }}
                              renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                  {option.name}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Search by Principal"
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "Search by Principal", // disable autocomplete and autofill
                                  }}
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-group label-figma">
                            <Autocomplete
                              id="distirbutorfilters"
                              sx={{ width: "100%" }}
                              options={distributors}
                              autoHighlight
                              getOptionLabel={(option) => option.name}
                              onChange={(event, value) => {
                                if (value) {
                                  setDistributorFilter(value.id);
                                } else {
                                  setDistributorFilter("");
                                }
                              }}
                              renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                  {option.name}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Search by Distributor"
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "Search by Distributor", // disable autocomplete and autofill
                                  }}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </>
                    ) : null}

                    <div className="col">
                      <div className="from-group label-figma">
                        <TextField
                          id="outlined-basic"
                          label="Search by Order Id"
                          variant="outlined"
                          value={orderIdFilter}
                          onChange={(e) => setOrderIdFilter(e.target.value)}
                          sx={{ width: "100%" }}
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-group label-figma">
                        <FormControl sx={{ width: "100%" }}>
                          <InputLabel id="demo-multiple-name-label">
                            Order Status
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            value={orderStatus}
                            onChange={(e) => setOrderStatus(e.target.value)}
                            input={<OutlinedInput label="Order Status" />}
                          >
                            {statusList.map((name) => (
                              <MenuItem key={name} value={name}>
                                {name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    {/* <div className="col-lg-3">
                      <div className="from-group label-figma">
                        <FormControl sx={{ width: "100%" }}>
                          <InputLabel id="demo-multiple-name-label">
                            {" "}
                            Status
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                            input={<OutlinedInput label="Status" />}
                          >
                            <MenuItem key={"Approved"} value={"true"}>
                              Approved
                            </MenuItem>
                            <MenuItem key={"Unapproved"} value={"false"}>
                              Unapproved
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div> */}

                    <div className="col">
                      <div className="form-group label-figma">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label="Start Date"
                            disableFuture
                            sx={{ width: "100%" }}
                            onChange={(newValue) => {
                              if (newValue !== null) {
                                // Parse the string date into a Date object
                                const parsedDate = new Date(newValue);

                                // Format the date as yyyy-mm-dd
                                const year = parsedDate.getFullYear();
                                const month = String(
                                  parsedDate.getMonth() + 1
                                ).padStart(2, "0");
                                const day = String(
                                  parsedDate.getDate()
                                ).padStart(2, "0");
                                const formattedDate = `${year}-${month}-${day}`;

                                // Set the formatted date in your state or perform any other actions
                                setStartDate(formattedDate);
                              } else {
                                // Handle the case where the date is null
                                setStartDate(null);
                              }
                            }}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-group label-figma">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label="End Date"
                            disableFuture
                            sx={{ width: "100%" }}
                            onChange={(newValue) => {
                              if (newValue !== null) {
                                // Parse the string date into a Date object
                                const parsedDate = new Date(newValue);

                                // Format the date as yyyy-mm-dd
                                const year = parsedDate.getFullYear();
                                const month = String(
                                  parsedDate.getMonth() + 1
                                ).padStart(2, "0");
                                const day = String(
                                  parsedDate.getDate()
                                ).padStart(2, "0");
                                const formattedDate = `${year}-${month}-${day}`;

                                // Set the formatted date in your state or perform any other actions
                                setEndDate(formattedDate);
                              } else {
                                // Handle the case where the date is null
                                setEndDate(null);
                              }
                            }}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row my-3">
                {count > 0 ? (
                  <ServerSideTable
                    pageSize={10}
                    page={0}
                    count={count}
                    functionName={getGellaryData}
                    data={gellaryData}
                    columns={
                      role === "ROLE_ADMIN"
                        ? columnsAdmin
                        : role === "ROLE_DISTRIBUTER"
                        ? columns
                        : role === "ROLE_SUB_ADMIN"
                        ? columnsSubAdmin
                        : columnsPrincipal
                    }
                    loading={loading}
                    idname="id"
                  />
                ) : (
                  <Box
                    sx={{
                      height: 600,
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {loading ? (
                      <CircularProgress />
                    ) : (
                      <p>
                        <b>No Data Available</b>
                      </p>
                    )}
                  </Box>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} size={"lg"} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Create New Order</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form noValidate validated={validated}>
            <Row className="mb-3">
              <Form.Group as={Col} md="4" controlId="validationCustom01">
                <Form.Label>
                  <b>Principal</b>
                </Form.Label>
                <Form.Select
                  required
                  onChange={(e) => setPrincipalselect(e.target.value)}
                >
                  <option value="">Select Principal</option>
                  {assignedPrincipalList?.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Form.Select>
                {/* <Form.Control.Feedback type="invalid">
                  Please select a principal.
                </Form.Control.Feedback> */}
              </Form.Group>

              <Form.Group as={Col} md="4" controlId="validationCustom02">
                <Form.Label>
                  <b>Payment Terms</b>
                </Form.Label>
                <Form.Select
                  required
                  onChange={(e) => setPaymentTerms(e.target.value)}
                >
                  <option value="">Select Payment Terms</option>
                  <option value="Advance payment">Advance payment</option>
                  <option value="Paid on delivery">Paid on delivery</option>
                  <option value="Paid on Shipment">Paid on Shipment</option>
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="validationCustom02">
                <Form.Label>
                  <b>PO Number</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  required
                  maxLength={15}
                  onChange={(e) => setPoNumber(e.target.value)}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="validationCustom01">
                <Form.Label>
                  <b>Is Proforma invoice required?</b>
                </Form.Label>
                <Form.Select
                  required
                  onChange={(e) => setInvoice(e.target.value)}
                >
                  <option value="">Select Invoice</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationCustom02">
                <Form.Label>
                  <b>Incoterms </b>
                </Form.Label>
                <Form.Select
                  required
                  onChange={(e) => setIncoTerms(e.target.value)}
                >
                  <option value="">Select Incoterm</option>
                  <option value="EXW">Ex works</option>
                  <option value="DAP">FOB</option>
                  <option value="CIF">CIF</option>
                  <option value="CIP">CIP</option>
                  <option value="DAP ">DAP </option>
                  <option value="DDP">DDP</option>
                </Form.Select>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="validationCustom01">
                <Form.Label>
                  <b>Is insurance required?</b>
                </Form.Label>
                <Form.Select
                  required
                  onChange={(e) => setInsurance(e.target.value)}
                >
                  <option value="">Select Insurance</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationCustom02">
                <Form.Label>
                  <b>Freight (Ex works)</b>
                </Form.Label>
                <Form.Select
                  required
                  onChange={(e) => setFreight(e.target.value)}
                >
                  <option value="">Select Freight</option>
                  <option value="Yes">Picked up by buyer</option>
                  <option value="No"> Shipped on account of buyer</option>
                  <option value="No"> Paid and shipped by seller</option>
                </Form.Select>
              </Form.Group>
            </Row>
            <Row>
              <Col>
                <Form.Group as={Col} md="12">
                  <Form.Label>
                    <b> Special Requirements</b>
                  </Form.Label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={description}
                    config={editorConfiguration}
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                      //  console.log('Editor is ready to use!', editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();

                      setDescription(data);
                    }}
                    onBlur={(event, editor) => {}}
                    onFocus={(event, editor) => {}}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col className="mt-3">
                <div className="file-input">
                  <label className="file-input__label" for="file-input">
                    <input
                      type="file"
                      id="multi-file-input"
                      class="file-input__input"
                      multiple
                      //onChange={(e) => setFile(e.target.files)}
                    />
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="upload"
                      class="svg-inline--fa fa-upload fa-w-16"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
                      ></path>
                    </svg>
                    <span>Upload file</span>
                  </label>
                </div>
                <br />
                <div
                  className="files"
                  id="fileList"
                  style={
                    fileCount > 0 ? { display: "block" } : { display: "none" }
                  }
                >
                  <h5>Files Selected</h5>
                  <ul className="Filelisting"></ul>
                </div>
              </Col>
              <Col style={{ textAlign: "right" }}>
                {/* <Button
                  className="mt-3 mr-5 file-input__label"
                  style={{ borderRadius: "4px" }}
                >
                  <a
                    style={{ color: "#fff" }}
                    target="_blank"
                    href="/assets/Purchase-Order-Template-01-TemplateLab.xlsx"
                  >
                    <i class="fa fa-solid fa-download"></i>
                    &nbsp; Download Template
                  </a>
                </Button> */}
                {/* <Button
                  className="mt-3 mr-5 file-input__label"
                  style={{ borderRadius: "4px" }}
                  variant="contained"
                  onClick={downloadTemplate}
                  disabled={loading} // Disable the button when loading is true
                >
                  {!loading && (
                    <i
                      className="fa fa-download"
                      style={{ marginRight: "5px" }}
                    />
                  )}
                  <span style={{ marginRight: "auto", marginLeft: "5px" }}>
                    <span>
                      {loading
                        ? "Downloading Template..."
                        : "Download Template"}
                    </span>
                  </span>
                  {loading && (
                    <CircularProgress
                      size={16}
                      style={{ marginRight: "5px" }}
                    />
                  )}
                </Button> */}
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
      {/* /*---------------------------Update----------------------------------*/}
      <Modal
        show={updateshow}
        onHide={updateHandleClose}
        size={"lg"}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Order</Modal.Title>
        </Modal.Header>
        {/* {load ? <LinearProgress /> : null}  */}
        <Modal.Body>
          <Form noValidate validated={validated}>
            <Row className="mb-3">
              <Form.Group as={Col} md="4" controlId="validationUpdateCustom01">
                <Form.Label>
                  <b>Principal</b>
                </Form.Label>
                <Form.Select
                  value={principal}
                  required
                  onChange={(e) => setPrincipal(e.target.value)}
                >
                  {assignedPrincipalList?.map((item) => (
                    <option
                      value={item.id}
                      selected={item.id == principal ? true : false}
                    >
                      {item.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="validationUpdateCustom02">
                <Form.Label>
                  <b>Payment Terms</b>
                </Form.Label>
                <Form.Select
                  required
                  onChange={(e) => setPaymentTerms(e.target.value)}
                  value={paymentTerms}
                >
                  <option value="Advance payment">Advance payment</option>
                  <option value="Paid on delivery">Paid on delivery</option>
                  <option value="Paid on Shipment">Paid on Shipment</option>
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="validationUpdateCustom03">
                <Form.Label>
                  <b>PO Number</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  required
                  maxLength={15}
                  onChange={(e) => setPoNumber(e.target.value)}
                  value={poNumber}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="validationUpdateCustom04">
                <Form.Label>
                  <b>Is Proforma invoice required?</b>
                </Form.Label>
                <Form.Select
                  onChange={(e) => setInvoice(e.target.value)}
                  value={invoice}
                >
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationUpdateCustom05">
                <Form.Label>
                  <b>Incoterms</b>
                </Form.Label>
                <Form.Select
                  onChange={(e) => setIncoTerms(e.target.value)}
                  value={incoTerms}
                >
                  <option value="EXW">Ex works</option>
                  <option value="DAP">FOB</option>
                  <option value="CIF">CIF</option>
                  <option value="CIP">CIP</option>
                  <option value="DAP ">DAP </option>
                  <option value="DDP">DDP</option>
                </Form.Select>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="validationUpdateCustom06">
                <Form.Label>
                  <b>Is insurance required?</b>
                </Form.Label>
                <Form.Select
                  onChange={(e) => setInsurance(e.target.value)}
                  value={insurance}
                >
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationUpdateCustom07">
                <Form.Label>
                  <b>Freight (Ex works)</b>
                </Form.Label>
                <Form.Select
                  onChange={(e) => setFreight(e.target.value)}
                  value={freight}
                >
                  {/* need to change the values*/}
                  <option value="Yes">Picked up by buyer</option>
                  <option value="No"> Shipped on account of buyer</option>
                  <option value="No"> Paid and shipped by seller</option>
                </Form.Select>
              </Form.Group>
            </Row>
            <Row>
              <Col>
                <Form.Group as={Col} md="12">
                  <Form.Label>
                    <b>Special Requirements</b>
                  </Form.Label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={description}
                    config={editorConfiguration}
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                      //  console.log('Editor is ready to use!', editor);
                    }}
                    onChange={(event, editor) => {
                      const updateddata = editor.getData();

                      setDescription(updateddata);
                    }}
                    onBlur={(event, editor) => {}}
                    onFocus={(event, editor) => {}}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col className="mt-3">
                <div className="file-input">
                  <label className="file-input__label" for="file-input">
                    <input
                      type="file"
                      id="multi-file-input"
                      class="file-input__input"
                      multiple
                      //onChange={(e) => setFile(e.target.files)}
                    />
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="upload"
                      class="svg-inline--fa fa-upload fa-w-16"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
                      ></path>
                    </svg>
                    <span>Upload file</span>
                  </label>
                </div>
                <br />
                <div
                  className="files"
                  id="fileList"
                  style={
                    fileCount > 0 ? { display: "block" } : { display: "none" }
                  }
                >
                  <h5>Files Selected</h5>
                  <ul className="Filelisting"></ul>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={updateHandleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={updatehandleSubmit}>
            Update Order
          </Button>
        </Modal.Footer>
      </Modal>

      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={5000}
          onClose={alertClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={alertClose} severity="error" sx={{ width: "100%" }}>
            {"Please fill all the require fields"}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
}

export default Order;
